<template>
  <div class="as">
    <el-dialog
        title="联系我们"
        width="40%"
        top="1vh"
        :visible.sync="centerDialogVisible"
        v-if="centerDialogVisible"
        @close="subCance"
        center>
      <el-form :model="form" label-width="140px" :rules="stRules" ref="ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input placeholder="请输入" v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input placeholder="请输入" v-model="form.phone" class="input-with-select" style="width: 60%;">
<!--            <el-button disable  slot="append" @click="sendSms">发送验证码</el-button>-->
          </el-input>
          <el-button style="max-width: 38%;"  @click="sendSms" :disabled="send_sms_button.disabled" type="primary">{{ send_sms_button.content }}</el-button>

        </el-form-item>
        <el-form-item label="验证码" prop="yzm">
          <el-input placeholder="请输入" v-model="form.yzm" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="qyname">
          <el-input placeholder="请输入" v-model="form.qyname" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item placeholder="请选择" label="酒店类型">
          <el-select v-model="form.hotel_type" style="width: 100%" placeholder="请选择">
            <el-option v-for="(item,index) in casesType" :key="index" :label="item.description" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间数量">
          <el-input placeholder="请输入" v-model="form.room_num" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="酒店所在地">
          <el-input placeholder="请输入" v-model="form.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="咨询产品">
          <el-checkbox-group v-model="form.cp_type">
            <el-checkbox v-for="(item,index) in cpType" :key="index"  :label="item.title" name="type" ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="有什么可以帮助您的">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="button_loading" @click="subCance">取 消</el-button>
        <el-button :loading="button_loading" type="primary" @click="sub">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getCases,addContactUs,productSel,sendSmsCode} from "@/api/index";
const TIME_COUNT = 60;
export default {
  name: 'ContactUs',
  props: {

    hidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      centerDialogVisible: false,
      form: {
        name: null,
        phone: null,
        yzm: null,
        qyname: null,
        hotel_type: null,
        room_num: null,
        address: null,
        cp_type: [],
        remark: null,
      },
      checkLists: [
        {
          id: 0,
          code: '中大型企业级PMS',
        },
        {
          id: 1,
          code: '小型企业快捷版PMS',
        },
        {
          id: 2,
          code: '餐饮系统',
        },
        {
          id: 3,
          code: '采购系统',
        },
        {
          id: 4,
          code: '会员系统',
        },
        {
          id: 5,
          code: '固定资产',
        },
        {
          id: 6,
          code: '人事系统',
        },
        {
          id: 7,
          code: '宴会销售',
        },
        {
          id: 8,
          code: '财务系统',
        },
        {
          id: 9,
          code: '其它',
        },
      ],
      casesType:[],
      cpType:[],
      send_sms_button: {
        content: '发送验证码',
        disabled: false,
      },
      count_down: {
        timer: undefined,
      },
      button_loading:false,
    };
  },
  created() {
    this.getCasesList();
    this.getCpList();
  },
  computed: {
    stRules() {
      const validatePhone = (rule, value, callback) => {

        // 判断输入框中是否输入手机号
        if (!value) {
          callback(new Error('手机号不能为空'))
        }
        //正则表达式进行验证手机号，从1开始，第二位是35789中的任意一位，以9数字结尾
        if (!/^1[35789]\d{9}$/.test(value)) {
          callback(new Error('手机号格式不正确'))
        }
        callback()
      }
      return {
        // ================================== 验证规则 Start ==================================
        name: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        concat: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入', trigger: 'blur'},
          { required: true, trigger: 'blur', min: 11, max: 11, message: '长度不符合'},
          { required: true, trigger: 'blur', validator: validatePhone}
        ],
        qyname: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        yzm: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        // ================================== 验证规则 End ==================================
      }
    },
  },
  methods: {
    sub(){
      // this.$emit('close',false)
      this.$refs.ruleForm.validate(async (valid) => {
        this.button_loading = true;
        if (valid) {

          await addContactUs(this.form).then((data) => {

            this.$notify({
              title: "提醒",
              dangerouslyUseHTMLString: true,
              message: "添加成功",
              type: 'success'
            })
            this.button_loading = false
            this.form={
              name: null,
              phone: null,
              yzm: null,
              qyname: null,
              hotel_type: null,
              room_num: null,
              address: null,
              cp_type: [],
              remark: null,

            }
            this.$emit('close',false)
          }).catch(() => {
            this.button_loading = false
          })
        }
      })

    },
    subCance(){
      this.form={
        name: null,
        phone: null,
        yzm: null,
        qyname: null,
        hotel_type: null,
        room_num: null,
        address: null,
        cp_type: [],
        remark: null,

      }
      this.$emit('close',false);
    },
    sendSms(){
      if(!this.form.phone){
        this.$alert('电话不能为空', '提示', { type: 'error', callback: action => { } })

      }else if(!/1[35789]\d{9}/.test(this.form.phone)){

      }
      else{
        // 发送验证码
        sendSmsCode({mobile_phone: this.form.phone}).then(data => {
          this.$alert('验证码已发送', '提示', { type: 'warning', callback: action => { } })

          // 启动倒计时
          this.startCountdown(TIME_COUNT);
        }).catch(error => {
        });
      }

    },
    // 获取客户案例
    getCasesList(){
      getCases(this.query).then(res => {
        this.casesType = res.data.type
      }).catch(() => {
      })
    },
    // 获取产品
    getCpList(){
      productSel({}).then(res => {
        this.cpType = res.data.list
      }).catch(() => {
      })
    },
    //启动倒计时
    startCountdown(count) {
      let temp_count = count;
      this.send_sms_button.disabled = true;
      this.send_sms_button.content = temp_count + '秒';
      this.smsInterval = temp_count;
      this.count_down.timer = setInterval(() => {
        if (temp_count > 0 && temp_count <= TIME_COUNT) {
          temp_count--;
          this.send_sms_button.content = temp_count + '秒';
          this.smsInterval = temp_count;
        } else {
          clearInterval(this.count_down.timer);
          this.count_down.timer = undefined;
          this.resetSendSmsButton();
          this.smsInterval = 0;
        }
      }, 1000);
    },
    // 重置发送验证码按钮
    resetSendSmsButton() {
      this.send_sms_button.disabled = false;
      this.send_sms_button.content = '发送验证码';
    },

  },
  watch:{
    hidden:{
      handler(newValue,oldValue){
        this.centerDialogVisible=newValue
      },
      deep:true
    }
  }
}
</script>

<style>
.el-form-item {
  margin-bottom: 20px;
}
</style>
