import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import {i18n} from "@/lang/index.js"
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
// Vue.use(ElementUI)
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value), size: 1000 });

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
