import Vue from 'vue'
import VueRouter from 'vue-router'
import { getApiToken } from "@/utils/userinfo"
import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  /*{
    path: '*',
    redirect: 'Index',
    name: 'Index',
    component: () => import('@/views/index/index')
  },*/
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index/index')
  },
  //首页
  /*{
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index/index')
  },*/
  //行业案例
  {
    path: '/hyal',
    name: 'Hyal',
    component: () => import('@/views/hyal/index')
  },
  //行业案例详情
  {
    path: '/hyal-info',
    name: 'HyalInfo',
    component: () => import('@/views/hyal/info')
  },
  //伙伴生态圈
  {
    path: '/hbstq',
    name: 'Hbstq',
    component: () => import('@/views/hbstq/index')
  },
  //关于Blanda
  {
    path: '/gy',
    name: 'Gy',
    component: () => import('@/views/gy/index')
  },
  //产品-pms
  {
    path: '/pms',
    name: 'Pms',
    component: () => import('@/views/cp/pms/index')
  },
  //产品-pos
  {
    path: '/pos',
    name: 'Pos',
    component: () => import('@/views/cp/pos/index')
  },
  //产品-sc
  {
    path: '/sc',
    name: 'Sc',
    component: () => import('@/views/cp/sc/index')
  },
  //产品-eng
  {
    path: '/eng',
    name: 'Eng',
    component: () => import('@/views/cp/eng/index')
  },
  //产品-ac
  {
    path: '/ac',
    name: 'Ac',
    component: () => import('@/views/cp/ac/index')
  },
  //产品-mms
  {
    path: '/mms',
    name: 'Mms',
    component: () => import('@/views/cp/mms/index')
  },
  //产品-picc
  {
    path: '/picc',
    name: 'Picc',
    component: () => import('@/views/cp/picc/index')
  },
  //产品-hrm
  {
    path: '/hrm',
    name: 'Hrm',
    component: () => import('@/views/cp/hrm/index')
  },
  //产品-eam
  {
    path: '/eam',
    name: 'Eam',
    component: () => import('@/views/cp/eam/index')
  },
  //售后服务
  {
    path: '/shfw',
    name: 'Shfw',
    component: () => import('@/views/shfw/index')
  },
  //解决方案-餐饮
  {
    path: '/jjfa/restaurant',
    name: 'Restaurant',
    component: () => import('@/views/jjfa/cy/index')
  },
  //解决方案-集团
  {
    path: '/jjfa/group',
    name: 'Group',
    component: () => import('@/views/jjfa/group/index')
  },
  //解决方案-增值
  {
    path: '/jjfa/zz',
    name: 'Zz',
    component: () => import('@/views/jjfa/zz/index')
  },
  //解决方案-公寓
  {
    path: '/jjfa/ams',
    name: 'Ams',
    component: () => import('@/views/jjfa/ams/index')
  },
  //解决方案-景区
  {
    path: '/jjfa/jq',
    name: 'Jq',
    component: () => import('@/views/jjfa/jq/index')
  },
  //解决方案-综合
  {
    path: '/jjfa/zh',
    name: 'Zh',
    component: () => import('@/views/jjfa/zh/index')
  },














  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/register'),
    meta: {
      title: 'yylAdmin - 注册'
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/feedback/feedback'),
    meta: {
      title: 'yylAdmin - 反馈'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/login'),
    meta: {
      title: 'yylAdmin - 登录'
    }
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('@/views/member/member'),
    meta: {
      title: 'yylAdmin - 个人中心'
    }
  }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  window.scrollTo(0,0);
  const loginList = ['Feedback', 'Member']
  const token = getApiToken()
  if (loginList.includes(to.name) && !token) {
    Message.error("请登录")
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
