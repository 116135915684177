/*
 * @Author: From-wh from-wh@hotmail.com
 * @Date: 2023-03-09 18:02:23
 * @FilePath: /admin/src/i18n/index.js
 * @Description:
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store/index.js';

import zhcnLocale from 'element-ui/lib/locale/lang/zh-CN';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhtwLocale from 'element-ui/lib/locale/lang/zh-TW';

import nextZhcn from '@/lang/zh-cn.js';
import nextEn from '@/lang/en.js';
import nextZhtw from '@/lang/zh-tw.js';

// 使用插件
Vue.use(VueI18n);
const messages = {
  'zh-CN': {
    ...zhcnLocale,
    message: {
      ...nextZhcn,
    },
  },
  'en-US': {
    ...enLocale,
    message: {
      ...nextEn,
    },
  },
  'zh-Hant': {
    ...zhtwLocale,
    message: {
      ...nextZhtw,
    },
  },
};
// 定义语言国际化内容
/**
 * 说明：
 * /src/lang 下的 js 为框架的国际化内容
 * /src/pages 下的 js 为各界面的国际化内容
 */
// 导出语言国际化
export const i18n = new VueI18n({
  // locale: store.state.themeConfig.themeConfig.globalI18n,
  locale:'zh-CN',
  fallbackLocale: 'zh-CN',
  // messages:store.state.themeConfig.themeConfig.globalI18nMess,
  messages:messages,
  silentTranslationWarn: true, // 去除国际化警告
});
