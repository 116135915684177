<template>
  <div :style="bg">
    <div class="content header-content">
      <el-row :gutter="0">
        <el-col :span="4" class="nav-item">
          <a href="./" class="nav-item" style="display: flex;align-items: center;width: 115px;">
            <img :src="logo" width="115" height="50" alt="" srcset="" />
          </a>
        </el-col>
        <el-col :span="14">
          <el-row :gutter="0">
            <el-container class="nav">
              <div class="nav-item"  :offset="10" @click="on_link('Index')">
<!--                <router-link class="nav-a" to="/index">首页</router-link>-->
                <div class="nav-a" :style="nav_color">首页</div>
              </div>
              <div class="nav-item" >
                <div class="nav-a" :style="nav_color" :class="content_item2?'active':''"  @mouseover="contentDis">产品 <i  :class="trans"></i></div>
              </div>
              <div class="nav-item-a">
                <!--              <router-link class="nav-a" to="/feedback">解决方案 <i class="el-icon-arrow-down trans"></i></router-link>-->
                <div class="nav-a" :style="nav_color" :class="content_item2?'active':''" @mouseover="contentDis2">解决方案 <i :class="trans2"></i></div>
                <div class="nav-item-abs" ref="nav-item-abs">
                  <div class="nav-item-abs-item" @click="on_link('Zh')">综合型酒店管理系统</div>
                  <div class="nav-item-abs-item" @click="on_link('Restaurant')">餐饮零售营销平台</div>
                  <div class="nav-item-abs-item" @click="on_link('Ams')">商务公寓快捷连锁</div>
                  <div class="nav-item-abs-item" @click="on_link('Group')">集团化业务中台</div>
                  <div class="nav-item-abs-item" @click="on_link('Jq')">智慧景区票务一卡通</div>
                  <div class="nav-item-abs-item" @click="on_link('Zz')">国际联号增值产品</div>
                </div>
              </div>
              <div class="nav-item"  @click="on_link('Hyal')">
<!--                <router-link class="nav-a" to="/hyal">行业案例</router-link>-->
                <div class="nav-a" :style="nav_color">行业案例</div>
              </div>
              <div class="nav-item" @click="on_link('Hbstq')">
<!--                <router-link class="nav-a" to="/hbstq">伙伴生态圈</router-link>-->
                <div class="nav-a" :style="nav_color">伙伴生态圈</div>
              </div>
              <div class="nav-item" @click="on_link('Shfw')">
<!--                <router-link class="nav-a" to="/shfw">售后服务</router-link>-->
                <div class="nav-a" :style="nav_color">售后服务</div>
              </div>
              <div class="nav-item"  @click="on_link('Gy')">
<!--                <router-link class="nav-a" to="/gy">关于Blanda</router-link>-->
                <div class="nav-a" :style="nav_color">关于Blanda</div>
              </div>
            </el-container>

          </el-row>
        </el-col>
        <el-col :span="3">
          <div style="color:#258cff;font-size: 18px;font-weight: bold;height: 70px;display: flex;justify-content: flex-start;align-items: center; ">
            400-900-1678
          </div>
        </el-col>
        <el-col :span="3" class="nav-item">
          <div class="nav-right-button">
            <div>
              <el-button style="border: 1px solid #258cff;background: #ffffff;color: #258cff">登录</el-button>
            </div>
            <div style="margin-left: 20px">
<!--              <el-button @click="contactUsDialogVisible=true" style="border: 1px solid #fb9337;background: #fb9337;color: #ffffff">免费使用</el-button>-->
              <el-button  style="border: 1px solid #258cff;background: #258cff;color: #ffffff">在线测试</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="content-min" v-on:mouseleave="gb" style="box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.08);" v-click-outside="handleBlur">
      <div class="nav-bar" ref="nav-bar">
        <div class="nav-bar-left">
          <div class="nav-bar-right-title mar-left-20">产品</div>
          <el-divider></el-divider>
          <div class="nav-bar-left-content">
            <div class="nav-bar-left-content-item" @click="on_link('Pms')" v-on:mouseenter="mouseFaImg(1)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==1? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-12.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-11.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">PMS前台客房（移动PMS）</div>
                <div class="nav-bar-left-content-item-div-note">一键直达更省心，权限管理更完善</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item" @click="on_link('Pos')" v-on:mouseenter="mouseFaImg(2)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==2? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-22.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-21.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">POS餐饮收银零售</div>
                <div class="nav-bar-left-content-item-div-note">智慧餐饮-让经营餐厅更容易</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item" @click="on_link('Sc')" v-on:mouseenter="mouseFaImg(3)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==3? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-32.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-31.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">S&C 宴会销售(宴会APP)</div>
                <div class="nav-bar-left-content-item-div-note">在线筹备宴会</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item" @click="on_link('Eng')" v-on:mouseenter="mouseFaImg(4)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==4? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-42.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-41.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">ENG工程报修（工程APP）</div>
                <div class="nav-bar-left-content-item-div-note">智能化管理、降低运营成本</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item" @click="on_link('Ac')" v-on:mouseenter="mouseFaImg(5)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==5? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-52.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-51.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">AC财务总账</div>
                <div class="nav-bar-left-content-item-div-note">为酒店提供数字化财务服务</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item" @click="on_link('Mms')" v-on:mouseenter="mouseFaImg(6)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==6? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-62.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-61.png' " width="30px" height="30px" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">MMS会员储值卡</div>
                <div class="nav-bar-left-content-item-div-note">储值、消费、电子券&实体券、SVIP微信会员移动服务</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item" @click="on_link('Picc')" v-on:mouseenter="mouseFaImg(7)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==7? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-72.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-71.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">PICC采购成本(采购APP)</div>
                <div class="nav-bar-left-content-item-div-note">实时掌握采购进度，降低采购成本</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item" @click="on_link('Hrm')" v-on:mouseenter="mouseFaImg(8)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==8? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-82.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-81.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">HRM人事薪资考勤(人事APP)</div>
                <div class="nav-bar-left-content-item-div-note">智能人力运营，引领人力资源数字化</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item" @click="on_link('Eam')" v-on:mouseenter="mouseFaImg(9)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==9? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-92.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-91.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">EAM固定资产（盘点小程序）</div>
                <div class="nav-bar-left-content-item-div-note">资产全周期数字化管理</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item"  v-on:mouseenter="mouseFaImg(10)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==10? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-22.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-21.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">BMS早餐核销系统</div>
                <div class="nav-bar-left-content-item-div-note">助力提升早餐服务体验</div>
              </div>
            </div>
            <div class="nav-bar-left-content-item"  v-on:mouseenter="mouseFaImg(11)">
              <div class="nav-bar-left-content-item-img"><img :src="nav_inde==11? 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-92.png': 'https://cdn.blanda.com.cn/assets/img/pub/nav/icon-91.png' " width="100%" height="100%" alt=""></div>
              <div class="nav-bar-left-content-item-div">
                <div class="nav-bar-left-content-item-div-title">OPI证件扫描系统</div>
                <div class="nav-bar-left-content-item-div-note">快速扫描、简单入住、一键完成</div>
              </div>
            </div>
            <i class="nav-bar-left-content-item-i"></i>
            <i class="nav-bar-left-content-item-i"></i>
            <i class="nav-bar-left-content-item-i"></i>
            <i class="nav-bar-left-content-item-i"></i>

          </div>
        </div>
<!--        <div class="nav-bar-right">
          <div class="nav-bar-right-title">解决方案</div>
          <el-divider></el-divider>
          <div class="nav-bar-right-content">
            <div class="nav-bar-right-content-item" @click="on_link('Restaurant')">
              <div class="nav-bar-right-content-item-title">餐饮</div>
              <div class="nav-bar-right-content-item-note">功能完备全面管理酒店</div>
            </div>
            <div class="nav-bar-right-content-item" @click="on_link('Ams')">
              <div class="nav-bar-right-content-item-title">公寓及商务快捷连锁</div>
              <div class="nav-bar-right-content-item-note">信息化管理提高工作效率</div>
            </div>
            <div class="nav-bar-right-content-item" @click="on_link('Group')">
              <div class="nav-bar-right-content-item-title">集团连锁</div>
              <div class="nav-bar-right-content-item-note">随时随地，监控房态与运营</div>
            </div>
            <div class="nav-bar-right-content-item" @click="on_link('Jq')">
              <div class="nav-bar-right-content-item-title" >景区</div>
              <div class="nav-bar-right-content-item-note">随时随地，监控房态与运营</div>
            </div>
            <div class="nav-bar-right-content-item" @click="on_link('Zz')">
              <div class="nav-bar-right-content-item-title" >国际酒店增值服务</div>
              <div class="nav-bar-right-content-item-note">助力酒店完善管理生态圈</div>
            </div>
            <div class="nav-bar-right-content-item" @click="on_link('Zh')">
              <div class="nav-bar-right-content-item-title">综合</div>
              <div class="nav-bar-right-content-item-note">员工更高效，酒店更轻松</div>
            </div>
            <i class="nav-bar-right-content-item-i"></i>
            <i class="nav-bar-right-content-item-i"></i>
            <i class="nav-bar-right-content-item-i"></i>
            <i class="nav-bar-right-content-item-i"></i>
          </div>
        </div>-->
      </div>


    </div>
    <ContactUs :hidden="contactUsDialogVisible" @close="contactUsDialogVisible=false"></ContactUs>
  </div>

</template>

<script>
// 自定义指令，用于处理点击外部区域的事件
const clickOutside = {
  bind(el, binding) {
    // 在元素上绑定一个点击事件监听器
    el.clickOutsideEvent = function (event) {
      // 检查点击事件是否发生在元素的内部
      if (!(el === event.target || el.contains(event.target))) {
        // 如果点击事件发生在元素的外部，则触发指令绑定的方法,将点击的event数据传过去
        binding.value(event);
      }
    };
    // 在文档上添加点击事件监听器
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    // 在元素上解除点击事件监听器
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};
import { getApiToken, getNickname, getAvatar } from '@/utils/userinfo'
import ContactUs from "@/components/ContactUs/index";
export default {
  directives: {
    "click-outside": clickOutside, // 注册自定义指令
  },
  name: 'AppHeader',
  components: {
    ContactUs,
  },
  props:{
    st:{
      type:Number,
      default () {
        return 0
      }
    }
  },
  data () {
    return {
      islogin: false,
      nickname: '',
      avatar: '',
      trans:'el-icon-arrow-down',
      trans2:'el-icon-arrow-down',
      // bg:'background: transparent',
      bg:'background: #ffffff',
      nab_icon_one:'el-icon-arrow-down',
      content_item:false,//产品详情
      content_item2:false,//解决方案
      nav_inde:-1,
      nav_color:'color:#000000',
      logo:'https://cdn.blanda.com.cn/assets/img/pub/logo-h.png',
      contactUsDialogVisible:false,//弹窗
    }
  },
  created () {
    this.isLogin()
  },
  methods: {
    handleBlur(event) {
      this.$refs['nav-bar'].style.height="0px";
      this.trans='el-icon-arrow-down';
      this.trans2='el-icon-arrow-down';
      this.nav_color='color:#000000'
    },
    isLogin () {
      const token = getApiToken()
      if (token) {
        this.nickname = getNickname()
        this.avatar = getAvatar()
        this.islogin = true
      }
    },
    contentDis(){
      this.bg='background: #ffffff';
      this.nav_color='color:#000000'
      if(this.trans=='el-icon-arrow-down'){
        this.trans='el-icon-arrow-up';
      }else{
        this.trans='el-icon-arrow-down';
      }
      this.trans2='el-icon-arrow-down';
      this.$refs['nav-bar'].style.height="400px";
    },
    contentDis2(){
      this.bg='background: #ffffff';
      this.nav_color='color:#000000'
      if(this.trans2=='el-icon-arrow-down'){
        this.trans2='el-icon-arrow-up';
      }else{
        this.trans2='el-icon-arrow-down';
      }
      this.trans='el-icon-arrow-down';
      this.$refs['nav-bar'].style.height="0px";
    },
    mouseFaImg(val){
      this.nav_inde=val;
    },
    on_link(str){
      this.$refs['nav-bar'].style.height="0px";
      this.trans='el-icon-arrow-down';
      this.trans2='el-icon-arrow-down';
      this.nav_color='color:#000000'
      /*const href = this.$router.resolve({
        name: str,
      }).href;
      //打开新的页面 到 href 这个页面
      window.open(href, "_self");*/
      this.$router.push({
        name:str,
        params:{
          0:str
        }
      }, () => {})
    },
    gb(){
      this.$refs['nav-bar'].style.height="0px";
      this.trans='el-icon-arrow-down';
      this.trans2='el-icon-arrow-down';
    }

  },
  watch:{
    st:{
      handler(newValue,oldValue){
        this.content_item=false
        this.content_item2=false
        this.trans='el-icon-arrow-down';
        this.$refs['nav-bar'].style.height="0px";
        /*if(this.$route.name=='Hyal'||this.$route.name=='Gy'||this.$route.name=='Pms'||this.$route.name=='Pos'){
          this.bg='background: #ffffff';
        }else{
          if(newValue>1){
            this.bg='background: #ffffff';
          }else {
            this.bg='background: transparent';

          }
        }*/
        /*if(this.$route.name=='Index'){
          if(newValue>1){
            this.bg='background: #ffffff';
          }else {
            this.bg='background: transparent';

          }
        }else{
          this.bg='background: #ffffff';
        }*/
        if(newValue>1){
          this.bg='background: #ffffff';
          this.nav_color='color:#000000'
          this.logo='https://cdn.blanda.com.cn/assets/img/pub/logo-h.png';
        }else {
          if(this.$route.name=='Index'||this.$route.name=='Hyal'){
            this.bg='background: #ffffff';
            // this.bg='background: transparent';
            this.nav_color='color:#000000'
            this.logo='https://cdn.blanda.com.cn/assets/img/pub/logo-h.png';
          }else if(this.$route.name=='Hbstq'||this.$route.name=='Shfw'||this.$route.name=='Gy'){
            // this.bg='background: transparent';
            this.bg='background: #ffffff';
            this.nav_color='color:#000000'
            this.logo='https://cdn.blanda.com.cn/assets/img/pub/logo-h.png';
          }else{
            // this.bg='background: #transparent';
            this.bg='background: #ffffff';
            this.nav_color='color:#000000'
            this.logo='https://cdn.blanda.com.cn/assets/img/pub/logo-h.png';
          }


        }



      },
      deep:true
    }
  }
}
</script>

<style scoped>
a{
  text-decoration:none;
}
.header-content{
  padding: 0 20px;
}
.nav-item {
  height: 70px;
  line-height: 70px;
  display: flex;
  align-items: center;
}
.nav-item:hover {
  cursor:pointer;
}
.nav{
  display: flex;
  /*justify-content: space-between;*/
}
.nav >div{
  /*margin-right: 40px;*/
  /*width: 50%;*/
  margin-right: 25px;
}
.nav-sel{
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
  color: #000;
}
.nav-right-button{
  display: flex;
  justify-content: flex-end;
}
.nav-a {
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
  /*color: #000;*/
  color: #333333;

}

.nav-a:hover {
  color: #333333;
  font-weight: 600;
  /*color: #121315;*/
}


/*内容部分 start*/
.nav-bar{
  display: flex;
  background: #ffffff;
  justify-content: space-between;
  /*padding: 20px 20px 70px 20px;*/
  padding: 0px 20px 0px 20px;
  height: 0px;
  transition: height 0.3s;
  overflow: hidden;
}
.nav-bar-left{
  /*width: 67%;*/
  width: 100%;
  display: flex;
  flex-flow: column;
}
.nav-bar-left-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.nav-bar-left-content-item{
  width: 20%;
  height: 87px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 3px;
  margin-bottom: 20px;
  cursor: pointer;
}
.nav-bar-left-content-item:hover .nav-bar-left-content-item-div-title{
  color: #0482F9;
}
.nav-bar-left-content-item-i{
  width: 20%;
  padding: 0 20px;
}

@media screen and (min-width: 768px) and (max-width: 1600px) {
  .nav-bar-left-content-item{
    width: 40%;
    height: 87px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .nav-bar-left-content-item-i{
    width: 40%;
  }

}
.nav-bar-left-content-item-img{
  width: 30px;
  height: 30px;
}
.nav-bar-left-content-item-div{
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: 15px;
}
.nav-bar-left-content-item-div-title{
  font-size: 16px;
  font-weight: 700;
  /*color: #0482F9;*/
  /*color: #1F1F1F;*/
  color: #333333;
  line-height: 25px;
}
.nav-bar-left-content-item-div-note{
  overflow: hidden;
  font-size: 14px;
  font-weight: normal;
  color: #717B91;
  line-height: 28px;
}




.nav-bar-right{
  width: 30%;
  display: flex;
  flex-flow: column;
}
.nav-bar-right-title{
  font-weight: normal;
  color: #1B1A1B;
  line-height: 18px;
}
.nav-bar-right-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
}
.nav-bar-right-content::-webkit-scrollbar {
  display: none;
}
.nav-bar-right-content-item{
  width: 40%;
  height: 87px;
  padding: 0 20px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(0deg, #F5FAFF 0%, #E9F3FD 100%);
  border-radius: 3px;
  margin-bottom: 20px;
  cursor: pointer;
}
.nav-bar-right-content-item-i{
  width: 40%;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .nav-bar-right-content-item{
    width: 80%;
    height: 87px;
    padding: 0 20px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    background: #a0d911;
    background: linear-gradient(90deg, #F5FAFF 0%, #E9F3FD 100%);
    border-radius: 3px;
    margin-bottom: 20px;
  }

}
.nav-bar-right-content-item:nth-of-type(1){
  background: linear-gradient(90deg, #F5FAFF 0%, #E9F3FD 100%);
  border-radius: 3px;
}
.nav-bar-right-content-item:nth-of-type(2){
  background: linear-gradient(90deg, #E9F1FF 0%, #D7E6FF 100%);
  border-radius: 3px;
}
.nav-bar-right-content-item:nth-of-type(3){
  background: linear-gradient(90deg, #e2f1fe  0%, #e2f1fe  100%);
  border-radius: 3px;
}
.nav-bar-right-content-item:nth-of-type(4){
  background: linear-gradient(90deg, #F5FAFF 0%, #E9F3FD 100%);
  border-radius: 3px;
}
.nav-bar-right-content-item:nth-of-type(5){
  background: linear-gradient(90deg, #FFF4D7 0%, #FFE8B9 100%);
}


.nav-bar-right-content-item-title{
  font-size: 16px;
  font-weight: normal;
  color: #1F1F1F;
  line-height: 28px;
}

.nav-bar-right-content-item-note{
  font-size: 14px;
  font-weight: normal;
  color: #666666;
  line-height: 28px;
}

/*内容部分 end*/


.nav-a-item {
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
  color: #000;
}

.nav-a-item:hover {
  /*color: #0089ff;*/
  font-weight: 600;
  color: #3287FF;
}


/*.nav-a:hover .trans{
  transform:rotate(180deg);
  color: #0089ff;
}*/
.nav-item-content{
  margin-top: 20px;
  padding: 0 80px 40px 80px;
}
.nav-item-content-title{
  color: #000000;
  font-size: 24px;
}
.nav-item-content-list{
  display: flex;
  justify-items: flex-start;
  align-items: center;
  font-size: 14px;

  padding: 0 0 8px 12px;
}
.nav-item-content-list >img{
  width: 50px;
  height: 50px;
  margin-right: 12px;
}
.nav-item-content-list-item{
}
.nav-item-content-list-item-title{
  font-size: 18px;
}
.nav-item-content-list-item-note{
  font-size: 16px;
  margin-top: 10px;
  color: #203763;
}

.right-100{
  margin-right: 100px;
}
.el-button{
  padding: 10px 20px;
}
.active{
  font-weight: 600;
  color: #121315;
}
.nav-item-a{
  height: 70px;
  line-height: 70px;
  display: flex;
  align-items: center;
}
.nav-item-abs{
  position: absolute;
  top: 70px;
  width: 250px;
  background: #ffffff;
  box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0 0 8px 8px;
  padding: 6px;
  visibility: hidden;
  transition: all 0.2s ease;

}
.nav-item-a:hover .nav-item-abs{
  transform:scale(1);
  visibility: visible;
}
.nav-item-abs-item{
  height: 50px;
  line-height: 50px;
  padding-left: 18px;
  text-decoration: none;
  color: #171a1d;
  border-radius: 6px;
  font-size: 17px;
  font-weight: 400;
  cursor: pointer;
}
.nav-item-abs-item:hover{
  background: #eff0f1;
}
</style>
