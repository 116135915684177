/**
 * 修改一下配置时，需要每次都清理 `window.localStorage` 浏览器永久缓存，配置才会生效
 */
const themeConfigModule = {
  namespaced: true,
  state: {
    themeConfig: {
      // 是否开启布局配置抽屉
      isDrawer: false,

      /**
       * 全局主题
       */
      // 默认 primary 主题颜色
      primary: '#409eff',
      // 菜单背景色
      menuBgColor: '#282c34',
      // 是否开启深色模式
      isIsDark: false,
      themeStyle: 'theme-1',
      // 默认初始语言，可选值"<zh-cn|en|zh-tw>"，默认 zh-cn
      globalI18n: 'zh-CN',
      // 语言的内容
      globalI18nMess: [],
    },
  },
  mutations: {
    // 设置布局配置
    getThemeConfig(state, data) {
      state.themeConfig = data;
    },
  },
  actions: {
    // 设置布局配置
    setThemeConfig({ commit }, data) {
      commit('getThemeConfig', data);
    },
  },
};

export default themeConfigModule;
