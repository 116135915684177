import request from '@/utils/request'

/**
 * 获取翻译类型
 * @param {array} data 请求数据
 */
export function LangTypeList(data) {
  return request({
    url: '/api/setting.LangType/list',
    method: 'post',
    data
  })
}

/**
 * 根据类型获取翻译
 * @param {array} data 请求数据
 */
export function getLang(data) {
  return request({
    url: '/api/setting.LangType/getLang',
    method: 'post',
    data
  })
}
/**
 * 根据酒店类型获取行业案例
 * @param {{limit: number, page: number, type: null}} data 请求数据
 */
export function getCases(data) {
  return request({
    url: '/api/cases.CustomerCases/getCases',
    method: 'post',
    data
  })
}
/**
 * 根据酒店类型获取行业案例
 * @param {array} data 请求数据
 */
export function info(data) {
  return request({
    url: '/api/cases.CustomerCases/info',
    method: 'post',
    data
  })
}

/**
 * 查询产品
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function productSel(data) {
  return request({
    url: '/api/cases.CustomerCases/productSel',
    method: 'post',
    data
  })
}
/**
 * 添加联系我们
 * @param {array} data 请求数据
 */
export function addContactUs(data) {
  return request({
    url: '/api/cases.CustomerCases/addContactUs',
    method: 'post',
    data
  })
}
/**
 * 添加加入我们
 * @param {array} data 请求数据
 */
export function addJoinUs(data) {
  return request({
    url: '/api/cases.CustomerCases/addJoinUs',
    method: 'post',
    data
  })
}

/**
 * 发送验证码
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function sendSmsCode(data) {
  return request({
    url: '/api/cases.CustomerCases/sendSmsCode',
    method: 'post',
    data
  })
}
