import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';
import themeConfig from './module/themeConfig';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  plugins: [
    new VuexPersistence({
      reducer: (state) => ({
        themeConfig: state.themeConfig,
      }),
      storage: window.localStorage,
    }).plugin,
  ],
  modules: {
    themeConfig,
  }
})
