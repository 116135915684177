<template>
  <div id="app" @wheel="showinfor($event)"  @scroll="showinfor($event)">
    <el-row>
      <el-col>
        <el-container>
          <el-header style="background: transparent;position:fixed;top: 0; width: 100%;z-index: 999">
            <app-header :st="i"></app-header>
          </el-header>
          <el-main id="app-main">
            <router-view />
          </el-main>
          <el-footer>
            <app-footer></app-footer>
          </el-footer>
        </el-container>
      </el-col>
    </el-row>
    <div class="sidebar-box">
        <div class="sidebar-box-item">
          <div class="sidebar-box-item-img">
            <i class="el-icon-headset"></i>
          </div>
          <div class="sidebar-box-item-title">在线客服</div>
        </div>
        <div class="sidebar-box-item-two">
          <div class="phone-abs">
            <div class="phone-abs-img"><i class="el-icon-headset"></i></div>
            <div  class="phone-abs-item">
              <div>产品咨询热线: <span>400-900-1678</span></div>
              <div>渠道咨询热线: <span>15310918767</span></div>
            </div>
          </div>
          <div class="sidebar-box-item-img">
            <i class="el-icon-phone-outline"></i>
          </div>
          <div class="sidebar-box-item-title">电话咨询</div>
        </div>
        <div class="sidebar-box-item-three">
          <div class="wx-abs">
            <div class="wx-abs-img"></div>
            <div class="">微信扫码 立即咨询</div>
          </div>
          <div class="sidebar-box-item-img">
            <i class="el-icon-chat-dot-round"></i>
          </div>
          <div class="sidebar-box-item-title">微信咨询</div>
        </div>
        <div class="sidebar-box-item">
          <div class="sidebar-box-item-img">
            <i class="el-icon-position"></i>
          </div>
          <div class="sidebar-box-item-title">在线测试</div>
        </div>
    </div>
    <div class="sidebar-box-top" ref="tops" @click="scrollTops()">
      <div class="sidebar-box-top-item">
        <i class="el-icon-upload2" style="font-size: 28px;color: #258cff;"></i>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader/AppHeader";
import AppFooter from "@/components/AppFooter/AppFooter";
import { Local } from '@/utils/storage.js';
export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
  },
  data () {
    return {
      i:0,
      scrollTop:0,//滚动高度
    }
  },
  mounted() {
    this.getLayoutThemeConfig();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 获取缓存中的布局配置
    getLayoutThemeConfig() {
      if (Local.get('themeConfigPrev')) {
        this.$store.dispatch('themeConfig/setThemeConfig', Local.get('themeConfigPrev'));
        document.documentElement.style.cssText = Local.get('themeConfigStyle');
      } else {
        Local.set('themeConfigPrev', this.$store.state.themeConfig.themeConfig);
      }
    },
    //鼠标滚动时间
    showinfor(event){
      if(this.scrollTop==0){
        this.i=0
      }else{
        this.i++
      }
    },
    handleScroll(){
      this.scrollTop = document.documentElement.scrollTop;//滚动高度
      if(this.scrollTop>500){
        this.$refs['tops'].style.visibility="visible";

      }else{
        this.$refs['tops'].style.visibility="hidden";
      }
    },
    //滚动到顶部
    scrollTops(){
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style>
@import "./assets/font/font.css";
@import "./assets/css/public.css";
@import "./assets/css/color.css";
body{
  /*font-family: PingFang Medium,PingFang,Sans-serif;*/
  /*font-family: HarmonyOS_Sans_Medium,Sans-serif;*/
  /*font-family: HarmonyOS_Sans_Regular,Sans-serif;*/
  /*font-family:  "Microsoft YaHei",serif;*/
  font-family:  "Segoe UI", -apple-system, system-ui, BlinkMacSystemFont, 'Microsoft YaHei', 'PingFang SC', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Hiragino Sans GB', sans-serif !important;
  margin: 0;
  font-size: 16px;
}
#app {
  min-width: 1280px;
  margin: 0 auto;
  position: relative;
}
#app-main {
  /*min-height: 860px;*/
}
.el-main{
  padding: 0;
}
.el-main::-webkit-scrollbar {
  width: 0;
}
.el-main::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.el-header{
  padding: 0;
}
.el-footer{
  padding: 0;
}
.sidebar-box {
  position: fixed;
  right: 0;
  bottom: 120px;
  width: 65px;
  z-index: 99999;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: 1px 1px 5px 1px rgb(126, 126, 127);
}
.sidebar-box-item{
  width: 65px;
  height: 70px;
  border-radius: 5px 0 0 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}
.sidebar-box-item-two{
  width: 65px;
  height: 70px;
  border-radius: 5px 0 0 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}
.sidebar-box-item-two:hover .phone-abs{
  visibility: visible;
}
.sidebar-box-item:nth-of-type(4){
  border-bottom: 0px solid #eeeeee;
}
.sidebar-box-item-img{
  width: 20px;
  height: 20px;
  font-size: 18px;
  margin-bottom: 10px;
  color: #258cff;
}
.sidebar-box-item-title{
  width: 100%;
  text-align: center;
}
.phone-abs{
  visibility: hidden;
  z-index: 999;
  background: #ffffff;
  position: absolute;
  left:-251px;
  width: 250px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 3px 8px 0px rgba(191,195,198,63);
}
.phone-abs-img{
  width: 30px;
  font-size: 18px;
  color:#258cff;
}
.phone-abs-item{
  display: flex;
  flex-flow: column;
  font-size: 14px;
}
.phone-abs-item span{
  color:#258cff;
}
.sidebar-box-item-three{
  width: 65px;
  height: 70px;
  border-radius: 5px 0 0 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}
.wx-abs{
  visibility: hidden;
  z-index: 999;
  background: #ffffff;
  position: absolute;
  left:-131px;
  top: -70px;
  width: 130px;
  height: 140px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 3px 8px 0px rgba(191,195,198,63);
  font-size: 12px;
}
.wx-abs-img{
  width: 110px;
  height: 110px;
  background-image: url("https://cdn.blanda.com.cn/assets/img/pub/ewm.png");
  background-repeat: no-repeat;
  background-position:bottom left;
  background-size:  110px  110px;
}
.sidebar-box-item-three:hover .wx-abs{
  visibility: visible;
}

/*回到顶部*/
.sidebar-box-top{
  position: fixed;
  right: 0;
  bottom: 30px;
  width: 65px;
  z-index: 99999;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 1px 1px 5px 1px rgb(126, 126, 127);
}
.sidebar-box-top-item{
  width: 65px;
  height: 70px;
  border-radius: 5px 0 0 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
</style>
